import React, { useContext, useEffect, useState } from "react";
import { RgbColor } from "react-colorful";
import styles from "../LoyaltyCard.module.css";
import CardMask from "assets/images/card-mask.svg";
import { colord } from "colord";
import placeholder from "assets/images/card-banner-placeholder.svg";
import appContext from "utils/app-context";
import Photo from "UI/Photo";
import { MainContext } from "pages/Main/Main";

const LoyaltyCardPreview = () => {
  const { user} = useContext(appContext);
  const { LoyaltyCardService } = useContext(MainContext);
  const {color,photos} = LoyaltyCardService
  
  const [image, setimage] = useState<string | null>(null)
  
  useEffect(() => {
    const photoUrl = 
      !photos[0]
        ? null
        : photos[0].file
          ? URL.createObjectURL(photos[0].file)
          : photos[0].image

    setimage(photoUrl)
  }, [photos])
  return (
    <div className={styles.cardPreviewContainer}>
      <div className={styles.cardPreviewCard}>
        <h3 className={styles.cardSettingsTitle}>Предпросмотр</h3>
        <div className={styles.cardWrapper}>
          <div
            className={styles.cardHeader}
            style={{ backgroundColor: colord(color).toHex(),backgroundImage:`url(${CardMask})` }}
          >
            {/* <img src={CardMask} alt="" className={styles.cardMask} /> */}
            <div className={styles.cardBalance}>
              <p>550</p> <span>Баланс карты</span>{" "}
            </div>
            <div className={styles.merchantName}>{user?.merchantName}</div>
            <span className={styles.cardTextSkeleton} />
          </div>
          <div className={styles.cardPhoto}>
            {/* <img src={photo || placeholder} alt="" /> */}
            <Photo
              placeholder="Ваш баннер"
              image={image}
              style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoyaltyCardPreview;
