import { useEffect, useRef, useState } from "react";
import { RgbColor } from "react-colorful";
import { toast } from "react-toastify";
import { notification } from "./use-notifications";
import loyaltyCardSettings from "api/loyalty-card/loyalty-card-settings";
import { colord } from "colord";
import { DropZoneFile } from "UI/FilesDropZone";
import LoyaltyCardSettings from "types/loyalty-card-settings";

export default () => {
  const [color, setColor] = useState<RgbColor>({ b: 0, g: 0, r: 0 });
  const [photos, setPhoto] = useState<DropZoneFile[]>([]);
  console.log("🚀 ~ photos:", photos)
  const [error, setError] = useState<string | null>(null);
  const [loading, setloading] = useState(true);
  const settings = useRef({ color, photos });
  const ischanged =
    !colord(settings.current.color).isEqual(color) ||
    settings.current.photos !== photos;


  const RestoreSettings = () => {
    const {color,photos} = settings.current
    setPhoto(photos)
    setColor(color)
  }

  const ApplySettings = (data:LoyaltyCardSettings) => {
    const c = colord(data.logoHex).toRgb();
    setColor(c);
    const newPhoto = data.bannerUrl ? [{
      file:null,
      image:data.bannerUrl || ''
    }] : []
    setPhoto(newPhoto);
    settings.current.color = c;
    settings.current.photos = newPhoto;
    setError(null)
  }

  const SaveSettings = async () => {
    const res = await loyaltyCardSettings({
      colorInHex: colord(color).toHex(),
      file: photos[0] ? photos[0] : null
    });
    if (!res) {
      toast.error("Не удалось сохранить настройки карты лояльности");
    } else if ("description" in res) {
      toast.error(
        `Ошибка при сохранении настроек карты лояльности:\n${res.description}`
      );
    } else {
      notification("Настройки сохраненны", "success");
      ApplySettings(res)
    }
  };

  const loadSettings = async () => {
    const res = await loyaltyCardSettings();
    if (!res) {
      setError("Не удалось получить настройки карты лояльности");
    } else if ("description" in res) {
      setError(
        `Ошибка при получении настроек карты лояльности:\n${res.description}`
      );
    } else {
      ApplySettings(res)
    }
    setloading(false);
  };

  useEffect(() => {
    loadSettings();
  }, []);
  return {
    color,
    photos,
    RestoreSettings,
    setColor,
    setPhoto,
    loading,
    setloading,
    SaveSettings,
    ischanged,
    error,
    setError,
    loadSettings,
  };
};
