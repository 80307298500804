import React, { useContext, useEffect, useState } from "react";
import styles from "../LoyaltyCard.module.css";
import { RgbaColorPicker, RgbColor, RgbColorPicker } from "react-colorful";
import { colord, extend } from "colord";
import FilesDropZone from "UI/FilesDropZone";
import { resizeAndCropImage } from "utils/image-utils";
import appContext from "utils/app-context";
import { MainContext } from "pages/Main/Main";

const LoyaltyCardSettings = () => {
  const {LoyaltyCardService} = useContext(MainContext)
  const {color,photos,setColor,setPhoto} = LoyaltyCardService

  const [hexValue, sethexValue] = useState(colord(color).toHex());
  const [editing, setediting] = useState(false);

  const setColorChannel = (value: string | number, chanel: keyof RgbColor) => {
    value = Number(value);
    value = Math.max(0, value);
    value = Math.min(value, 255);
    const newColor = { ...color };
    newColor[chanel] = value;
    setColor(newColor);
  };

  useEffect(() => {
    if (editing || hexValue.length < 7) return;
    const c = colord(hexValue);
    if (c.isValid()) setColor(c.toRgb());
  }, [hexValue]);

  useEffect(() => {
    sethexValue(colord(color).toHex());
  }, [color]);

  return (
    <div className={styles.cardSettingsWrapper}>
      <div className={styles.colorSettings}>
        <div className={styles.colorSettingsHeader}>
          <h3 className={styles.cardSettingsTitle}>Цвет карты лояльности</h3>
          <p className={styles.cardSettingsTitleText}>
            Добавьте параметры HEX или RGB вашего фирменного цвета,
            либо выберите цвет вручную.
          </p>
        </div>
      </div>
      <div className={styles.colorInputsContainer}>
        <div className={styles.colorInputGroup}>
          <label htmlFor="hex">HEX</label>
          <input
            type="text"
            id="hex"
            // onFocus={() => setediting(true)}
            // onBlur={() => setediting(false)}
            value={hexValue}
            placeholder="#000000"
            onChange={({ target: { value } }) => {
              sethexValue(value);
            }}
          />
        </div>
        <div className={styles.colorRGBInputsContainer}>
          <div className={styles.colorInputGroup}>
            <label htmlFor="R">R</label>
            <input
              type="text"
              id="R"
              value={color.r}
              onChange={({ target: { value } }) => setColorChannel(value, "r")}
            />
          </div>
          <div className={styles.colorInputGroup}>
            <label htmlFor="G">G</label>
            <input
              type="text"
              id="G"
              value={color.g}
              onChange={({ target: { value } }) => setColorChannel(value, "g")}
            />
          </div>
          <div className={styles.colorInputGroup}>
            <label htmlFor="B">B</label>
            <input
              type="text"
              id="B"
              value={color.b}
              onChange={({ target: { value } }) => setColorChannel(value, "b")}
            />
          </div>
        </div>
      </div>
      <div className={styles.colorPicker}>
        <RgbColorPicker
          color={color}
          onChange={setColor}
          onMouseDown={() => setediting(true)}
          onMouseUp={() => setediting(false)}
        />
      </div>
      <div className={styles.colorSettingsHeader}>
        <h3 className={styles.cardSettingsTitle}>Баннер</h3>
        <p className={styles.cardSettingsTitleText}>
          Добавьте изображение под картой. <br />
          Форматы: JPG, JPEG. Размер: 1020×390 пикселей. Максимум: 1 Мб
        </p>
      </div>
      <FilesDropZone
        maxFiles={1}
        accept={{ "image/*": [".jpg", ".jpeg"] }}
        maxSize={1024 * 1024}
        dimentions={{h:390,w:1020}}
        files={photos}
        setFiles={photos => setPhoto(photos)}
        canRemoveRemoteFiles
      />
    </div>
  );
};

export default LoyaltyCardSettings;
