import AiDiscountInfo from "types/ai-discount-info";
import ErrorResponce from "types/error-response";
import OrdersResponse, { Order } from "types/orders-responce";
import StoreAddress from "types/store-address";

export default async (
  orderID: Order["id"],
  customerId: Order["customerId"]
): Promise<AiDiscountInfo | null | ErrorResponce> => {
  const token = localStorage.getItem("token");
  if (!token || !customerId) return null;
  try {
    const API_URL = new URL(process.env.REACT_APP_API_URL as string);
    API_URL.pathname += "analytics/ai-discount";
    API_URL.searchParams.set("customerId", customerId);
    API_URL.searchParams.set("orderId", orderID);
    const responce = await fetch(API_URL, {
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": token,
      },
      method: "GET",
    });
    return await responce.json();
  } catch (error) {
    console.log("🚀 ~ error:", error);
    return null;
  }
};
