import React, { createContext, useContext } from "react";
import { Outlet, useLocation, useNavigate, useRoutes } from "react-router-dom";
import NavigationBar from "UI/NavigationBar";
import PageWrapper from "UI/PageWrapper";
import routes from "./Main.routes";
import styles from "./Main.module.css";
import TopBar from "UI/TopBar";
import { AnimatePresence } from "framer-motion";
import useAddresses from "hooks/use-addresses";
import appContext from "utils/app-context";
import useLoyaltyCard from "hooks/use-loyalty-card";
import useStories from "hooks/use-stories";

type MainContextType = {
  addressesService: ReturnType<typeof useAddresses>;
  StoriesService:ReturnType<typeof useStories>
  LoyaltyCardService:ReturnType<typeof useLoyaltyCard>
};

export const MainContext = createContext<MainContextType>({} as MainContextType);

const Main = () => {
  const location = useLocation();
  const element = useRoutes(routes.map(({ route }) => route));
  const addressesService = useAddresses()
  const rootPath = location.pathname.split("/")[2];
  const nav = useNavigate()
  
  const StoriesService = useStories()
  const LoyaltyCardService = useLoyaltyCard()

  const {hiddenRoutes} = useContext(appContext)
  
  if(hiddenRoutes.includes(rootPath)) {
    nav('/main',{replace:true})
    return null
  }

  if (!element) return null;


  const contextValue = {
    StoriesService,LoyaltyCardService,
    addressesService
  }

  return (
    <MainContext.Provider value={contextValue}>
      <PageWrapper className={styles.mainPage}>
        <NavigationBar routesList={routes} />
        <div className={styles.pageContent}>
          <TopBar />
          <AnimatePresence mode="wait">
            <PageWrapper
              data-vertical
              key={rootPath}
              className="section-wrapper"
            >
              {React.cloneElement(element)}
            </PageWrapper>
          </AnimatePresence>
        </div>
      </PageWrapper>
    </MainContext.Provider>
  );
};

export default Main;
