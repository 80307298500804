import { diff } from "deep-diff"
import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"

const initialHidden = [
    'analytics'
]

export default () => {
    const [hiddenRoutes, sethiddenRoutes] = useState(initialHidden)
    const keys = useRef(new Set())
    const nav = useNavigate()
    const handleChanges = () => {
        const analyticsKeys = ['AltLeft','KeyA','KeyN'].join()
        const currentKeys = Array.from(keys.current).join()
        if(analyticsKeys === currentKeys) {
            sethiddenRoutes([])
            nav('main/analytics')
        }
        
    }

    useEffect(() => {
        const handleKeyDown = (e:KeyboardEvent) => {
            if(e.repeat) return
            keys.current.add(e.code)
            handleChanges()
        } 
        const handleKeyUp = (e:KeyboardEvent) => {
            if(e.repeat) return
            keys.current.delete(e.code)
            handleChanges()

        }   
        const handleBlur = () => {
            keys.current.clear()
        }
        window.addEventListener('keydown',handleKeyDown)
        window.addEventListener('keyup',handleKeyUp)
        window.addEventListener('blur',handleBlur)
        return () => {
            window.removeEventListener('keydown',handleKeyDown)
            window.removeEventListener('keyup',handleKeyUp)
            window.removeEventListener('blur',handleBlur)
        }
    }, [])

    return {hiddenRoutes}
}