import Auth from "pages/Auth/Auth"
import Loading from "pages/Loading/Loading"
import { RouteObject } from "react-router-dom"
import Transactions from "./pages/Transactions/Transactions"
import RouteItem from "types/route-item"
import MainResults from "./pages/MainResults/MainResults"
import Redicrect from "UI/Redicrect"

const routes:RouteItem[] = [
    {
        route:{
            path:'',
            element:<Redicrect to="transactions"/>,
        },
        title:''
    },
    {
        route:{
            path:'transactions',
            element:<Transactions/>,
        },
        title:'Транзакции'
    },
    // {
    //     route:{
    //         path:'results',
    //         element:<MainResults/>
    //     },
    //     title:'Главные результаты'
    // },
    
]
export default routes