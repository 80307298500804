import React, { useContext, useState } from 'react'
import styles from './RatingFilter.module.css'
import OrdersFilters from 'types/orders-filters'
import Button from 'UI/Button'
import filterStyles from "../../Transactions.module.css";
import Icon from 'UI/Icon';
import { FilterContext } from '../../../../components/DropDown/DropDown';
import { toast } from 'react-toastify';

type Props = {
    selected:OrdersFilters['ratings']
  setSelected:(list:OrdersFilters['ratings']) => void
}

const RatingFilter = ({selected,setSelected}: Props) => {
    const {close} = useContext(FilterContext)
    const [list, setlist] = useState(selected)
  
    const handleClick = (item:number) => {
        setlist(prev => {
          if(prev.includes(0)) prev = prev.filter(r => r !== 0)
          if(prev.includes(item)) {
            return prev.filter(r => r !== item)
          } else return [...prev,item]
        })
      }
  return (
    <div className={styles.body}>
    <ul className={styles.list}>
    <li
          className={`${styles.all} ${!list.length && styles.selected}`}
          onClick={() => setlist([])}
          >
          <i/>
            <span>Все оценки</span>
        </li>
    {[5,4,3,2,1].map(((n,i) => {
      const isSelected = list.includes(n)
      return (
        <li
          key={i}
          className={isSelected ? styles.selected : ''}
          onClick={() => handleClick(n)}
          >
          <i/>
          <div>
            <Icon icon='tagStar'/>
            <p>{n}</p>
          </div>
        </li>
      )
    }))}
      <li
          className={(list.length === 1 && list[0] === 0) ? styles.selected : ''}
          onClick={() => {
            setlist([0])
          }}
          >
          <i/>
            <p>Без оценки</p>
        </li>
    </ul>
    <div className={styles.actionContainer}>
      <Button
        className={filterStyles.buttonReset}
        onClick={() => setlist([])}
      >
        Сбросить
      </Button>
      <Button
        className={filterStyles.buttonConfirm}
        onClick={() => {
          setSelected(list)
          close()
        }}
      >
        Применить
      </Button>
    </div>
  </div>
  )
}

export default RatingFilter