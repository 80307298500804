import auth, { AuthResponse } from "api/auth/auth";
import refresh from "api/auth/refresh";
import getProfile from "api/get-profile";
import { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AppContextType from "types/app-context";
import ModalProps from "types/modal";
import User from "types/user";
import useStories from "./use-stories";
import useLoyaltyCard from "./use-loyalty-card";
import useHiddenRoutes from "./use-hidden-routes";

export const useAppData = () => {
  const [modal, setModal] = useState<ModalProps | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
    !!localStorage.getItem("token")
  );
  const {hiddenRoutes} = useHiddenRoutes()
  const [language, setLanguage] = useState<string>("en");
  const navigate = useNavigate();
  const location = useLocation();
  const updateTimer = useRef<NodeJS.Timeout | null>(null);
  const lastActiveTime = useRef<number>(Date.now());

  

  const updateUserInfo = async () => {
    const res = await getProfile();
    if (res && "username" in res) setUser(res);
  };

  const handleLogin = async (username: string, password: string) => {
    try {
      const response = await auth(username, password);
      if (response && "token" in response) {
        updateCredentials(response);
        navigate("/main");
      }
      return response;
    } catch (error) {
      console.error("Login failed", error);
    }
  };

  const updateCredentials = (response: AuthResponse) => {
    setIsAuthenticated(true);
    localStorage.setItem("token", response.token);
    localStorage.setItem("refresh-token", response.refreshToken);
    const deathTime = new Date(Date.now() + response.refreshPeriod * 1000);
    localStorage.setItem("death-time", deathTime.toString());
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refresh-token");
    localStorage.removeItem("death-time");
    setIsAuthenticated(false);
    setUser(null);
    navigate("/auth");
  };

  const checkTokenExpiry = async () => {
    const deathTimeString = localStorage.getItem("death-time");
    if (!deathTimeString) {
      handleLogout();
      return;
    }
    const deathTime = new Date(deathTimeString).getTime();
    const remainingTime = deathTime - Date.now();

    if (remainingTime <= 0) {
      handleLogout();
    } else if (remainingTime <= 5 * 60 * 1000) { // If less than 5 minutes
      const refreshToken = localStorage.getItem("refresh-token");
      if (!refreshToken) {
        handleLogout();
        return;
      }
      const res = await refresh(refreshToken);
      if (res && "token" in res) {
        updateCredentials(res);
      } else {
        handleLogout();
      }
    }
  };

  const monitorTokenLife = () => {
    checkTokenExpiry();
    updateTimer.current = setInterval(checkTokenExpiry, 60 * 1000);
  };

  const handleVisibilityChange = () => {
    if (document.visibilityState === "hidden") {
      lastActiveTime.current = Date.now();
    } else if (document.visibilityState === "visible") {
      const inactiveDuration = Date.now() - lastActiveTime.current;
      const deathTimeString = localStorage.getItem("death-time");
      if (deathTimeString) {
        const deathTime = new Date(deathTimeString).getTime();
        if (Date.now() >= deathTime) {
          handleLogout();
        } else if (inactiveDuration > 5 * 60 * 1000) {
          checkTokenExpiry();
        }
      }
    }
  };

  const changeLanguage = (newLanguage: string) => {
    setLanguage(newLanguage);
  };

  const AppContextValue: AppContextType = {
    user,
    isAuthenticated,
    language,
    handleLogin,
    handleLogout,
    changeLanguage,
    modal,
    setModal,
    hiddenRoutes
  };

  useEffect(() => {
    if (isAuthenticated) {
      monitorTokenLife();
      updateUserInfo();
    }
    return () => {
      if (updateTimer.current) clearInterval(updateTimer.current);
    };
  }, [isAuthenticated]);

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (location.pathname === "/") return;

    if (!isAuthenticated && location.pathname !== "/auth") {
      navigate("/auth", { replace: true });
    }
  }, [isAuthenticated, location, navigate]);

  return {
    AppContextValue,
  };
};
